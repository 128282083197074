/**
 * 新加报告
 * 考试成绩和排名
 * 二十个最匹配专业选科要求
 */

import { Service } from '@/utils/Service'

// 二十个最匹配专业得选科要求
export function gettypesubjects (data) {
  return Service({
    url: '/majormatchingeval/gettypesubjects/',
    data: data
  })
}

// 展示学生的考试成绩和排名情况
export function getexamgrades (data) {
  return Service({
    url: '/examresults/getexamgrades/',
    data: data
  })
}

// 获取选科组合的推荐值
export function subjectadvice (data) {
  return Service({
    url: '/subjectselection/subjectadvice/',
    data: data
  })
}