<!-- 二十个最匹配专业选科要求 -->
<template>
  <div class="main">
    <div class="main-title"><span>二十个最匹配专业选科要求</span></div>
    <div class="main-parts-wrapper">
      <div class="main-part">
        <div class="part-title">二十个最匹配专业选科要求</div>
        <div>
          <table
            class="lj-table lj-table-bordered lj-table-centered lj-table-radius lj-table-striped lj-table-hover"
          >
            <thead>
              <tr>
                <th class="lj-text-middle">专业名称</th>
                <th class="lj-text-middle">必选科目</th>
                <th class="lj-text-middle">多选一科目</th>
                <th class="lj-text-middle">推荐选择科目</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in majorsData" :key="index">
                <td>
                  {{ item.type_name }}
                </td>
                <td>
                  {{ item.r1 }}
                </td>
                <td class="td-text-left">
                  {{ item.r2 }}
                </td>
                <td>
                  {{ item.r3 }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="main-buttons-wrapper">
      <a
        class="lj-btn lj-btn-default lj-btn-block main-button"
        @click="jumpPage('reportCenter')"
        >返回目录</a
      >
    </div>
  </div>
</template>

<script>
import './../../../Public/utils/chartjs/Chart'
import './../../../Public/utils/highcharts/highcharts'
import { gettypesubjects } from './../../../api/report'
export default {
  data: function () {
    return {
      majorsData: []
    }
  },
  computed: {
    isMobile: function () {
      return navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
    }
  },
  created: function () {
    gettypesubjects({}).then(res => {
      console.log(res)
      this.majorsData = res.majorsData
    })
  },
  methods: {
    jumpPage: function (dest) {
      dest === '/'
        ? this.$router.replace({ path: dest })
        : this.$router.push({ path: dest })
    }
  }
}
</script>

<style lang="less">
@import url('./../../../Public/resources/css/templates/home/major/std_result4.min.css');
.main-title {
  padding: 0;
}

@media only screen and (max-width: 480px) {
  .main {
    padding: 1.6rem 0;
  }

  .main .main-parts-wrapper {
    padding: 1rem 0;
  }
}
</style>
